export const environment = {
    production: true,
    banorte: false,
    ente: "H. Ayuntamiento de Concordia",
    administracion: "2024 - 2027",
    url_escudo: "../assets/concordia/escudo.png",
    url_administracion: "../assets/concordia/concordia_logo_admin_24_27.png",
    url_footer: "../assets/concordia/LogoFinal.jpg",
    direcciones: [
        "Calle. B Juarez",
        "C. P. 82600",
        "Concordia, Sinaloa"
    ],
    telefonos: [
        "694 968 0002",
        ""
    ],
    emails: [
        "presidencia@concordia.gob.mx",
        "tesorería@concordia.gob.mx"
    ],
    url_aviso: "http://concordia.gob.mx/",
    url_webapi: "https://pagosconcordia.azurewebsites.net/api/",
    url_sitio: "https://pagosconcordia.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "012",
    municipio: "CONCORDIA",
    firebase: {
        apiKey: "AIzaSyCcVcLLkvtAMVMFP4t6L2VesssXcVPho28",
        authDomain: "pagosconcordia-41bbc.firebaseapp.com",
        projectId: "pagosconcordia-41bbc",
        storageBucket: "pagosconcordia-41bbc.appspot.com",
        messagingSenderId: "215706171703",
        appId: "1:215706171703:web:6d12c261b13e4f9f6682b2",
        measurementId: "G-ENJE57BMC6"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Concordia para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/concordia/pasos.png',
    img_ayuda_2: '../assets/concordia/ubicarclavecatastral.png',
    img_ayuda_3: '../assets/concordia/consultaadeudo.png'
};
